import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

// Component Imports
import { H3, H2, B1 } from './page-elements';

// Asset Imports
import AimroQuote from '../images/svg/aimro-quote.svg';

// Styled Components
const CTATile = styled.div`
  background-color: ${({ theme }) => theme.colors.secondary[100]};
  /* background-color: #103341; */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='199' viewBox='0 0 100 199'%3E%3Cg fill='%231d4959' fill-opacity='0.4'%3E%3Cpath d='M0 199V0h1v1.99L100 199h-1.12L1 4.22V199H0zM100 2h-.12l-1-2H100v2z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  overflow: hidden;
  box-shadow: 0px 4px 6px rgba(126, 143, 162, 0.05),
    0px 10px 15px rgba(126, 143, 162, 0.1);
  margin: 0 auto;
  padding: ${({ theme }) => theme.spacing[8]};
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: -150px;
    right: -50px;
    width: 40%;
    height: 120%;
    background: url(${AimroQuote}) center/contain no-repeat;
    background-position: center right;
  }

  @media (min-width: 37.5em) {
    padding: ${({ theme }) => theme.spacing[20]};
  }
`;

const CTATitle = styled(H2)`
  color: ${({ theme }) => theme.colors.white};
  width: 60%;
  text-align: left;
  line-height: ${({ theme }) => theme.lineHeight.tight};

  &:after {
    content: none;
  }

  @media (min-width: 27em) {
    margin-bottom: ${({ theme }) => theme.spacing[14]};
  }

  @media (min-width: 37.5em) {
    width: 75%;
    max-width: 600px;
  }
`;

const CTASubtitle = styled(H3)`
  color: ${({ theme }) => theme.colors.secondary[400]};
  margin-bottom: ${({ theme }) => theme.spacing[8]};
  text-align: left;

  @media (min-width: 37.5em) {
    margin-bottom: ${({ theme }) => theme.spacing[8]};
  }
`;

const CTAButton = styled(B1)`
  width: 100%;
  @media (min-width: 27em) {
    width: unset;
  }
`;

const ContactCTA = () => (
  <CTATile>
    <CTASubtitle>Get in touch.</CTASubtitle>
    <CTATitle>Want to find out more?</CTATitle>
    <Link to="contact" style={{ textDecoration: `none` }}>
      <CTAButton>Contact Us</CTAButton>
    </Link>
  </CTATile>
);

export default ContactCTA;
