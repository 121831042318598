import React, { Component } from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import SEO from '../components/seo';
import Container from '../components/container';
import Bubbles from '../components/bubbles';
import ContactCTA from '../components/contactCTA';

import {
  H2,
  H3,
  H4,
  P,
  P2,
  B2,
  B3,
  Tile,
  Col3,
  BubbleIcon,
  Section,
  StyledLink,
} from '../components/page-elements';

import ComplianceIcon from '../images/svg/compliance.inline.svg';
import PracticesIcon from '../images/svg/practices.inline.svg';
import InnovationIcon from '../images/svg/innovation.inline.svg';
import SpecialismIcon from '../images/svg/specialism.inline.svg';
import QualityIcon from '../images/svg/quality.inline.svg';
import StandardsIcon from '../images/svg/standards.inline.svg';
// Remove layout
import Layout from '../components/layout';
import shuffleArray from '../helpers/shuffleArray';

const HeroSection = styled.section`
  background-color: ${({ theme }) => theme.colors.primary.brand};
  padding: ${({ theme }) => theme.spacing[24]} 0;
  overflow-x: hidden;
  width: 100%;

  @media (min-width: 48em) {
    padding: ${({ theme }) => theme.spacing[72]} 0
      ${({ theme }) => theme.spacing[56]} 0;
  }
`;

const H1 = styled.h1`
  font-size: ${({ theme }) => theme.fontSize['5xl']};
  font-family: ${({ theme }) => theme.font.headings};
  font-weight: ${({ theme }) => theme.fontWeight.black};
  color: ${({ theme }) => theme.colors.white};
  text-transform: uppercase;
  line-height: ${({ theme }) => theme.lineHeight.tight};
  letter-spacing: ${({ theme }) => theme.letterSpacing.widest};
  margin-bottom: ${({ theme }) => theme.spacing[20]};

  span {
    font-weight: ${({ theme }) => theme.fontWeight.regular};
  }

  @media (min-width: 37.5em) {
    font-size: ${({ theme }) => theme.fontSize['6xl']};
  }
  @media (min-width: 90.063em) {
    font-size: ${({ theme }) => theme.fontSize['7xl']};
  }
`;

const HeroSVGClip = styled.svg`
  position: absolute;
  height: 0;
  width: 0;
`;

const HeroImgDiv = styled.div`
  width: 70%;
  padding-top: 85%; /* Maintain aspect ratio */
  background: ${({ theme }) => theme.colors.secondary.brand}
    url(${props => props.img});
  background-size: cover;
  background-position: center top;
  -webkit-clip-path: url(#quote);
  clip-path: url(#quote);
  position: absolute;
  right: -265px;
  bottom: 70px;
  z-index: 3;
  display: none;

  @media (min-width: 48em) {
    display: block;
  }
  @media (min-width: 64em) {
    right: -315px;
  }
`;

const Line1 = styled.hr`
  width: 2px;
  height: 300px;
  background: ${({ theme }) => theme.colors.secondary[300]};
  border: none;
  position: absolute;
  top: -150px;
  /* left: 0; */
  margin: 0;
`;

const WhatSection = styled(Section)`
  background-color: ${({ theme }) => theme.colors.white};
  position: relative;
`;

const WhatRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: 37.5em) {
    flex-direction: row;
  }

  > p {
    margin-left: 0;
    max-width: none;
  }
`;

const WhyAimroSection = styled(Section)`
  position: relative;
`;

const Line2 = styled.hr`
  width: 2px;
  height: 150px;
  background: ${({ theme }) => theme.colors.secondary[300]};
  border: none;
  position: absolute;
  top: 0;
  left: calc(50% - 1px);
  margin: 0;
`;
const ParallaxImage = styled(BackgroundImage)`
  height: 350px;
  background-repeat: no-repeat;
  background-size: cover;
  margin: ${({ theme }) => theme.spacing[48]} 0;

  /* Mobile browsers will unfortunately incorrectly say they support this, so we have to disable on tablets or looks rubbish.. */
  @supports (background-attachment: fixed) {
    @media (min-width: 85em) {
      height: 600px;
      &:before {
        background-attachment: fixed;
      }

      &:after {
        background-attachment: fixed;
      }
    }
  }
`;

const TenQTile = styled(Tile)`
  margin: ${({ theme }) => theme.spacing[32]} auto 0 auto;
  width: 95%;
  background-color: ${({ theme }) => theme.colors.secondary[800]};
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='199' viewBox='0 0 100 199'%3E%3Cg fill='%23f1f7f8' fill-opacity='0.4'%3E%3Cpath d='M0 199V0h1v1.99L100 199h-1.12L1 4.22V199H0zM100 2h-.12l-1-2H100v2z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
  padding: ${({ theme }) => theme.spacing[10]}
    ${({ theme }) => theme.spacing[8]};
  justify-content: space-between;
  align-items: flex-start;

  @media (min-width: 48em) {
    flex-direction: row;
    padding: ${({ theme }) => theme.spacing[10]}
      ${({ theme }) => theme.spacing[12]};
  }

  @media (min-width: 64em) {
    width: 80%;
  }
`;

const TenQTitle = styled(H3)`
  margin: 0;
  margin-bottom: 3em;
  max-width: 300px;
  text-align: left;

  @media (min-width: 37.5em) {
    margin-bottom: 2em;
  }
  @media (min-width: 48em) {
    margin-bottom: 0;
    /* padding-right: 5em; */
  }
`;

const TenQButton = styled(StyledLink)`
  align-self: flex-end;
  width: 100%;

  > button {
    width: 100%;
  }

  @media (min-width: 27em) {
    width: unset;
  }
`;

const ImageCollageContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(9, 1fr);
  margin: 0 auto;
  width: 90%;
  user-select: none;
  max-width: 975px;

  /* Force grid to have square cells */
  ::before {
    content: '';
    padding-bottom: 100%;
    display: inline-block;
    vertical-align: top;
  }

  @media (min-width: 64em) {
    width: 70%;
  }
  @media (min-width: 90.063em) {
    width: 55%;
  }
`;

const CollageImg = styled(BackgroundImage)`
  /* border-radius: ${({ theme }) => theme.borderRadius.default}; */
  overflow: hidden; /* needed for border radius */
  background-size: cover;
  background-repeat: no-repeat;
`;

const CollageImg01 = styled(CollageImg)`
  grid-column: 1 / span 5;
  grid-row: 4 / span 5;
`;

const CollageImg02 = styled(CollageImg)`
  grid-column: 4 / span 6;
  grid-row: 1 / span 6;
`;

const CollageImg03 = styled(CollageImg)`
  grid-column: 6 / span 7;
  grid-row: 5 / span 5;
`;

const OurMembersSection = styled(Section)`
  text-align: center;
`;

const ContactUsSection = styled(Section)`
  padding-top: 0;
  @media (min-width: 37.5em) {
    padding-top: ${({ theme }) => theme.spacing[40]};
  }
`;

const shuffle = array =>
  [...Array(array.length)]
    .map((...args) => Math.floor(Math.random() * (args[1] + 1)))
    .reduce((a, rv, i) => ([a[i], a[rv]] = [a[rv], a[i]]) && a, array);

class IndexPage extends Component {
  render() {
    const { data } = this.props;

    return (
      <>
        {/* Remove layout */}
        <Layout>
          <SEO title="Home" />
          <HeroSection>
            <Container>
              <div
                style={{
                  display: `flex`,
                  justifyContent: `space-between`,
                  position: `relative`,
                }}
              >
                <div>
                  <H1>
                    <span>
                      Better <br />
                      Research,
                    </span>
                    <br /> Better
                    <br /> Outcomes.
                  </H1>
                  <H3 style={{ textAlign: `left`, color: `white` }}>
                    ASSOCIATION OF IRISH MARKET <br />
                    RESEARCH ORGANISATIONS
                  </H3>
                </div>
                <HeroImgDiv img={data.sanityTemp.image.asset.fluid.src} />
                <HeroSVGClip>
                  <defs>
                    <clipPath id="quote" clipPathUnits="objectBoundingBox">
                      <path d="M0.5,0 C0.224,0,0,0.182,0,0.406 C0,0.63,0.224,0.812,0.5,0.812 V1 C0.5,1,1,0.697,1,0.406 C1,0.182,0.776,0,0.5,0" />
                    </clipPath>
                  </defs>
                </HeroSVGClip>
              </div>
            </Container>
          </HeroSection>
          <WhatSection>
            <Container style={{ position: `relative` }}>
              <Line1 />
              <WhatRow>
                <H3
                  style={{ textAlign: 'left', flex: `1`, marginBottom: `5rem` }}
                >
                  What we do
                </H3>
                <P style={{ flex: `1`, marginBottom: 0 }}>
                  AIMRO strives to make representations on behalf of the
                  industry to relevant authorities, to advance and safeguard the
                  interests of the industry and to procure and maintain high
                  professional standards.
                </P>
              </WhatRow>
            </Container>
          </WhatSection>
          <WhyAimroSection>
            <Line2 />
            <Container>
              <H3>Why choose an Aimro company?</H3>
              <H2>
                <strong>1.</strong> Advantage
              </H2>
              <P style={{ textAlign: 'center' }}>
                The advantage of knowing that you are receiving the latest, best
                quality advice from experts in their field.
              </P>

              <Col3>
                <Tile>
                  <BubbleIcon>
                    <QualityIcon />
                  </BubbleIcon>
                  <H4>Quality</H4>
                  <P2>
                    AIMRO members are experts in uncovering insights using the
                    best quality approach, ensuring you can trust the findings.
                  </P2>
                </Tile>
                <Tile>
                  <BubbleIcon>
                    <SpecialismIcon />
                  </BubbleIcon>
                  <H4>Specialism</H4>
                  <P2>
                    All AIMRO members uncover insights as their main line of
                    business, so you can rely on 100% focus and understanding of
                    the task at hand.
                  </P2>
                </Tile>
                <Tile>
                  <BubbleIcon>
                    <InnovationIcon />
                  </BubbleIcon>
                  <H4>Innovation</H4>
                  <P2>
                    AIMRO members are at the forefront of industry innovation
                    and learning, ensuring the best training and up to date
                    techniques for all staff working on your projects.
                  </P2>
                </Tile>
              </Col3>
            </Container>
            <ParallaxImage
              fluid={data.sanityTemp.image4.asset.fluid}
            ></ParallaxImage>
            <Container>
              <H2>
                <strong>2.</strong> Peace of Mind
              </H2>
              <P style={{ textAlign: 'center' }}>
                The peace of mind to make confident decisions in the knowledge
                that your research will be robust, reliable and conducted with
                integrity.
              </P>
              <Col3>
                <Tile>
                  <BubbleIcon>
                    <StandardsIcon />
                  </BubbleIcon>
                  <H4>Standards</H4>
                  <P2>
                    As the association representing the research, insight and
                    analytics industry in Ireland, we promote the highest
                    professional standards throughout the sector, in close
                    collaboration with international associations including
                    ESOMAR and the MRS (UK).
                  </P2>
                </Tile>
                <Tile>
                  <BubbleIcon>
                    <PracticesIcon />
                  </BubbleIcon>
                  <H4>Best Practices</H4>
                  <P2>
                    AIMRO members all sign up to follow AIMRO and international
                    best practice codes of conduct to gathering insight,
                    ensuring you get the highest quality research, to make
                    insight based decisions with confidence.
                  </P2>
                </Tile>
                <Tile>
                  <BubbleIcon>
                    <ComplianceIcon />
                  </BubbleIcon>
                  <H4>Compliance</H4>
                  <P2>
                    AIMRO provides leadership and direction for the industry,
                    liaising with government, to ensure that AIMRO members
                    comply and adhere to all data protection, processing, tax
                    and fieldwork requirements.
                  </P2>
                </Tile>
              </Col3>
              <StyledLink to="/standards">
                <B3>See more details</B3>
              </StyledLink>

              <TenQTile>
                <TenQTitle>10 Questions you should ask of any poll.</TenQTitle>
                <TenQButton to="polling-questions">
                  <B2>Find out now</B2>
                </TenQButton>
              </TenQTile>
            </Container>
          </WhyAimroSection>
          <ImageCollageContainer>
            <CollageImg01 fluid={data.sanityTemp.image4.asset.fluid} />
            <CollageImg03 fluid={data.sanityTemp.image2.asset.fluid} />
            <CollageImg02 fluid={data.sanityTemp.image3.asset.fluid} />
          </ImageCollageContainer>
          <OurMembersSection>
            <Container style={{ textAlign: `center` }}>
              <H3>Who are we?</H3>
              <H2>Our Members</H2>
              <P style={{ marginBottom: 0 }}>
                Our members make up the majority of the top market research
                companies in Ireland.
              </P>
            </Container>
            <Bubbles memberData={data.allSanityMember.nodes} />
            <Container>
              <StyledLink to="/members">
                <B3>See all members</B3>
              </StyledLink>
            </Container>
          </OurMembersSection>
          <ContactUsSection>
            <Container>
              <ContactCTA />
            </Container>
          </ContactUsSection>
        </Layout>
      </>
    );
  }
}

export const indexPageQuery = graphql`
  {
    sanityTemp {
      image {
        asset {
          fluid {
            src
          }
        }
      }
      image4 {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
      image2 {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
      image3 {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
    allSanityMember {
      nodes {
        companyName
        iconLogo {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
        iconBGColour {
          hex
        }
        logo {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
